import { CheckIcon } from '@heroicons/react/outline'
import { buildClassesWithDefault } from '../../../utils/StyleHelper'
import React, { HTMLAttributes, PropsWithChildren } from 'react'

export type CheckInfoProps = HTMLAttributes<HTMLSpanElement> & {
    checked?: boolean
    clickIcon?: () => void
}

const CheckInfo = ({ clickIcon, className, children }: PropsWithChildren<CheckInfoProps>) => {
    return (
        <span className={buildClassesWithDefault('flex items-center', className)}>
            <CheckIcon className='h-4 w-4 border border-gray-500 mr-1 rounded text-primary' onClick={clickIcon} />
            {children}
        </span>
    )
}

export default CheckInfo
