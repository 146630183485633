import { buildClassesWithDefault } from '../../../utils/StyleHelper'
import { mergeRefs } from 'react-merge-refs'
import Checkbox, { CheckboxProps } from '../checkbox/Checkbox'
import React, { ForwardedRef, MouseEvent, forwardRef, useRef } from 'react'

export type TableCheckboxProps = Omit<CheckboxProps, 'hideLabel'>

const TableCheckbox = (
    { onClick, disabled, readOnly, ...props }: TableCheckboxProps,
    ref: ForwardedRef<HTMLInputElement>
) => {
    const checkboxRef = useRef<HTMLInputElement>(null)

    const toggleCheckbox = (event: MouseEvent<HTMLSpanElement>) => {
        if (!disabled && !readOnly) {
            checkboxRef.current.click()
            event.stopPropagation()
        }
    }

    return (
        <span
            className={buildClassesWithDefault(
                'table-checkbox absolute w-full h-full left-0 top-0 flex items-center justify-center',
                {
                    'cursor-pointer': !disabled && !readOnly
                }
            )}
            onClick={toggleCheckbox}
        >
            <Checkbox
                {...props}
                ref={mergeRefs([checkboxRef, ref])}
                hideLabel
                disabled={disabled}
                onClick={event => {
                    event.stopPropagation()
                    onClick?.(event)
                }}
            />
        </span>
    )
}

export default forwardRef(TableCheckbox)
