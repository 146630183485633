import { RowData, Table } from '@tanstack/react-table'
import Pagination, { PaginationProps } from '../pagination/Pagination'
import React from 'react'

export type TablePaginationProps<TData extends RowData> = Omit<PaginationProps, 'pageCount' | 'page' | 'onChange'> & {
    table: Table<TData>
}

const TablePagination = <TData extends RowData>({ table, ...props }: TablePaginationProps<TData>) => {
    const onChange = (page: number) => {
        return table.setPageIndex(page - 1)
    }

    return (
        <Pagination
            {...props}
            page={table.getState().pagination.pageIndex + 1}
            pageCount={table.getPageCount()}
            onChange={onChange}
        />
    )
}

export default TablePagination
