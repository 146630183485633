import { PropsWithChildren, createContext, useContext, useState } from 'react'

export type OfferPriceSelectorState = {
    show: boolean
    showForId?: number
    selectedItems?: number[]
}

export type DemandTableContextState = {
    offerPriceSelector?: OfferPriceSelectorState
    setOfferPriceSelector: (state: OfferPriceSelectorState) => void
}

const DemandTableContext = createContext<DemandTableContextState>({} as DemandTableContextState)

export const useDemandTableContext = () => {
    const { setOfferPriceSelector, offerPriceSelector } = useContext(DemandTableContext)

    const showAddPriceOfferToOrdesDialog = (priceOfferId?: number) => {
        return setOfferPriceSelector({
            showForId: priceOfferId,
            show: true,
            selectedItems: []
        })
    }

    return {
        offerPriceSelector,
        setOfferPriceSelector,
        showAddPriceOfferToOrdesDialog
    }
}

export const DemandTableProvider = ({ children }: PropsWithChildren<{}>) => {
    const [offerPriceSelector, setOfferPriceSelector] = useState<OfferPriceSelectorState>()

    return (
        <DemandTableContext.Provider
            value={{
                offerPriceSelector,
                setOfferPriceSelector
            }}
        >
            {children}
        </DemandTableContext.Provider>
    )
}
