import { AxiosError } from 'axios'
import { MakeAxiosPaginatedResponse } from '../../../services/Service.types'
import { Order } from '../../../services/api/order/OrderService.types'
import { deleteOrder } from '../../../services/api/order/OrderService'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import useAxios from '../../useAxios'

const useOrderDelete = () => {
    const axiosInstance = useAxios()
    const queryClient = useQueryClient()

    return useMutation<{}, AxiosError, number>(
        ['deleteOrder'],
        async id => {
            return await deleteOrder(axiosInstance, id)
        },
        {
            onSuccess: (data, id) => {
                queryClient.removeQueries(['order', id], { exact: true })
                queryClient.setQueriesData<MakeAxiosPaginatedResponse<Order>>(['orders'], prev => {
                    return {
                        ...prev,
                        data: prev.data.filter(item => {
                            return item.id !== id
                        })
                    }
                })
            }
        }
    )
}

export default useOrderDelete
