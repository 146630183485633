import { AxiosInstance, AxiosRequestConfig } from 'axios'
import { MakeAxiosResponse } from '../../Service.types'
import { Product } from './ProductService.types'

export const getProductList = async (
    axiosInstance: AxiosInstance,
    config: AxiosRequestConfig = {}
): Promise<Product[]> => {
    const {
        data: { data: products }
    } = await axiosInstance.get<MakeAxiosResponse<Product[]>>('product', config)
    return products
}
