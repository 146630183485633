import { BsExclamationTriangle } from 'react-icons/bs'
import {
    ColumnDef,
    ColumnFilter,
    ColumnSort,
    PaginationState,
    RowSelectionState,
    Row as TableRow,
    createColumnHelper,
    getCoreRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    useReactTable
} from '@tanstack/react-table'
import { ShortOrder } from '../../../../services/api/order/OrderService.types'
import { buildClasses } from '../../../../utils/StyleHelper'
import { buildSelectColumn } from '../../../base/table/Table.utils'
import { formatCurrency } from '../../../../utils/Helper'
import { formatDate } from '../../../../utils/DateHelper'
import { useTranslation } from 'next-i18next'
import Button from '../../../base/button/Button'
import Card from '../../../base/card/Card'
import CardActions from '../../../base/card/CardActions'
import CardText from '../../../base/card/CardText'
import CheckInfo from '../../../base/check-info/CheckInfo'
import Column from '../../../base/grid/Column'
import DemandOrderTableProductFilter from '../../demand-order-shared/table/DemandOrderTableProductFilter'
import DemandOrderTableProductNameCell from '../../demand-order-shared/table/DemandOrderTableProductNameCell'
import DemandStatusBadge from '../detail/DemandStatusBadge'
import DemandTableActionCell from './DemandTableActionCell'
import DemandTableActionToast from './DemandTableActionToast'
import Link from 'next/link'
import React, {
    Dispatch,
    ForwardedRef,
    ReactNode,
    SetStateAction,
    forwardRef,
    useEffect,
    useImperativeHandle,
    useMemo,
    useState
} from 'react'
import Row from '../../../base/grid/Row'
import Table from '../../../base/table/Table'
import TablePagination from '../../../base/table/TablePagination'
import Tooltip from '../../../base/tooltip/Tooltip'
import useOrderPage from '../../../../hooks/api/order/useOrderPage'
import useTheme from '../../../../hooks/useTheme'
import useUserCurrency from '../../../../hooks/useUserCurrency'

export type DemandTableChildren = (
    renderTable: () => ReactNode,
    renderPagination: () => ReactNode,
    renderActions: () => ReactNode
) => ReactNode

export type DemandTableProps = {
    hideActions?: boolean
    showCreateAction?: boolean
    showShowAllAction?: boolean
    pagination?: boolean
    filtering?: boolean
    sorting?: boolean
    selecting?: boolean
    canDelete?: boolean
    initialSortBy?: ColumnSort[]
    onClickRow?: (order: TableRow<ShortOrder>) => void
    renderCustomActions?: (order: TableRow<ShortOrder>) => React.ReactNode
    initialFilters?: ColumnFilter[]
    disabledFilters?: string[]
    height?: number
    minHeight?: number
    rounded?: boolean
    sticky?: boolean
    stickyPagination?: boolean
    renderOnlyTable?: boolean
    disableActionColumn?: boolean
    onSelectItems?: (demandIds: number[]) => void
    selectOnClickRow?: boolean
    allowActionToast?: boolean
    children?: DemandTableChildren
    customEmpty?: ReactNode
    hasHeader?: boolean
    pageSize?: number
    dashboard?: boolean
    hasFiltersRow?: boolean
}

export type DemandTableRef = {
    setFilters: Dispatch<SetStateAction<ColumnFilter[]>>
    setSorts: Dispatch<SetStateAction<ColumnSort[]>>
    setPagination: Dispatch<SetStateAction<PaginationState>>
}

const DemandTable = (
    {
        children,
        hideActions,
        showCreateAction,
        showShowAllAction,
        pagination: enabledPagination = true,
        filtering: enabledFiltering = true,
        sorting: enabledSorting = true,
        selecting: enabledSelecting = true,
        canDelete = true,
        rounded,
        height,
        minHeight,
        initialSortBy = [],
        initialFilters = [],
        onClickRow,
        renderCustomActions,
        sticky,
        stickyPagination,
        renderOnlyTable,
        disabledFilters = [],
        disableActionColumn = false,
        selectOnClickRow,
        onSelectItems,
        allowActionToast,
        customEmpty,
        hasHeader = true,
        pageSize = 15,
        dashboard = false,
        hasFiltersRow = true
    }: DemandTableProps,
    ref: ForwardedRef<DemandTableRef>
) => {
    const currency = useUserCurrency()
    const { t } = useTranslation()
    const { dateFormat } = useTheme()
    const [filters, setFilters] = useState<ColumnFilter[]>(initialFilters)
    const [sorts, setSorts] = useState<ColumnSort[]>(initialSortBy)
    const [pagination, setPagination] = useState<PaginationState>({ pageSize, pageIndex: 0 })
    const [selection, setSelection] = useState<RowSelectionState>({})
    const { data: { data: orders = [], meta } = {}, isLoading } = useOrderPage({ filters, sorts, pagination })

    useEffect(() => {
        setSelection({})
    }, [pagination])

    useEffect(() => {
        onSelectItems?.(
            Object.entries(selection)
                .filter(([, value]) => {
                    return value
                })
                .map(([index]) => {
                    return orders[parseInt(index)].id
                })
        )
    }, [selection])

    useImperativeHandle(
        ref,
        () => {
            return {
                setSorts,
                setFilters,
                setPagination
            }
        },
        []
    )

    const columns = useMemo<ColumnDef<ShortOrder>[]>(() => {
        const columnHelper = createColumnHelper<ShortOrder>()
        const columns = []

        if (enabledSelecting) {
            columns.push(buildSelectColumn(columnHelper))
        }

        {
            dashboard
                ? columns.push(
                      ...[
                          columnHelper.accessor('ts', {
                              size: 90,
                              header: t('frontend.dashboard.demand.table.creation_date'),
                              cell: context => {
                                  return formatDate(context.getValue(), dateFormat)
                              },
                              meta: {
                                  filtering: 'date'
                              }
                          }),
                          columnHelper.accessor('number', {
                              // size: 124,
                              header: t('frontend.dashboard.demand.table.number'),
                              enableColumnFilter: !disabledFilters.includes('number')
                          }),
                          columnHelper.accessor('productName', {
                              // size: 140,
                              header: t('frontend.dashboard.demand.table.product'),
                              cell: context => {
                                  return <DemandOrderTableProductNameCell value={context.getValue()} />
                              },
                              enableSorting: false,
                              enableColumnFilter: !disabledFilters.includes('productName'),
                              meta: {
                                  filtering: props => {
                                      return <DemandOrderTableProductFilter {...props} />
                                  }
                              }
                          }),
                          columnHelper.accessor('price', {
                              size: 140,
                              header: t('frontend.dashboard.demand.table.price'),
                              cell: context => {
                                  if (context.row.original.currency) {
                                      return formatCurrency(context.getValue(), context.row.original.currency)
                                  }
                                  return '-'
                              },
                              filterFn: 'equals',
                              meta: {
                                  filtering: 'number'
                              }
                          }),
                          columnHelper.accessor('status', {
                              size: 124,
                              header: t('frontend.dashboard.demand.table.status'),
                              cell: context => {
                                  return (
                                      <div className='flex w-full justify-end'>
                                          <DemandStatusBadge status={context.getValue()} />
                                      </div>
                                  )
                              },
                              enableColumnFilter: false,
                              enableSorting: false
                          })
                      ]
                  )
                : columns.push(
                      ...[
                          columnHelper.accessor(row => row.customLabel || row.number, {
                              size: 110,
                              id: 'number',
                              header: t('frontend.dashboard.demand.table.number'),
                              cell: context => {
                                  const { customLabel, number } = context.row.original
                                  return (
                                      <>
                                          {customLabel && <div className='font-bold'>{customLabel}</div>}
                                          <div>{number}</div>
                                      </>
                                  )
                              },
                              enableColumnFilter:
                                  !disabledFilters.includes('customLabel') && !disabledFilters.includes('number'),
                              meta: {
                                  filterTooltip: t('frontend.dashboard.demand.table.tooltip')
                              }
                          }),
                          columnHelper.accessor('productName', {
                              size: 110,
                              header: t('frontend.dashboard.demand.table.product'),
                              cell: context => {
                                  return <DemandOrderTableProductNameCell value={context.getValue()} />
                              },
                              enableSorting: false,
                              enableColumnFilter: !disabledFilters.includes('productName'),
                              meta: {
                                  filtering: props => {
                                      return <DemandOrderTableProductFilter {...props} />
                                  }
                              }
                          }),
                          columnHelper.accessor('price', {
                              size: 80,
                              header: t('frontend.dashboard.demand.table.price'),
                              cell: context => {
                                  if (context.row.original.currency) {
                                      return formatCurrency(context.getValue(), context.row.original.currency)
                                  }
                                  return '-'
                              },
                              filterFn: 'equals',
                              meta: {
                                  filtering: 'number'
                              }
                          }),
                          columnHelper.accessor('ts', {
                              size: 80,
                              header: t('frontend.dashboard.demand.table.creation_date'),
                              cell: context => {
                                  return formatDate(context.getValue(), dateFormat)
                              },
                              meta: {
                                  filtering: 'date'
                              }
                          }),
                          columnHelper.accessor('amount', {
                              size: 60,
                              header: t('frontend.dashboard.demand.table.number_of_pieces'),
                              cell: cell => {
                                  return `${cell.getValue()} ks`
                              },
                              enableSorting: false,
                              enableColumnFilter: false
                          }),
                          columnHelper.accessor('status', {
                              size: 90,
                              header: t('frontend.dashboard.demand.table.status'),
                              cell: ({
                                  getValue,
                                  row: {
                                      original: { hasGhost }
                                  }
                              }) => {
                                  return (
                                      <div className='items-center flex gap-3'>
                                          <DemandStatusBadge status={getValue()} />
                                          {hasGhost && (
                                              <Tooltip
                                                  placement='top'
                                                  content={t('frontend.dashboard.demand.table.status.draft.tooltip')}
                                                  textColor='primary'
                                                  lazy={false}
                                              >
                                                  <BsExclamationTriangle className='h-4 w-4 text-red-700' />
                                              </Tooltip>
                                          )}
                                      </div>
                                  )
                              },
                              enableColumnFilter: false,
                              enableSorting: false
                          })
                      ]
                  )
        }

        if (!disableActionColumn) {
            columns.push(
                columnHelper.display({
                    size: 130,
                    id: 'action',
                    header: t('frontend.dashboard.demand.table.action'),
                    cell: context => {
                        return renderCustomActions ? (
                            renderCustomActions(context.row)
                        ) : (
                            <DemandTableActionCell row={context.row} canDelete={canDelete} />
                        )
                    },
                    enableSorting: false
                })
            )
        }

        return columns
    }, [
        canDelete,
        currency,
        dateFormat,
        disableActionColumn,
        disabledFilters,
        enabledSelecting,
        dashboard,
        renderCustomActions,
        t
    ])

    const table = useReactTable<ShortOrder>({
        columns,
        data: orders,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        pageCount: meta?.lastPage,
        state: {
            pagination,
            columnFilters: filters,
            sorting: sorts,
            rowSelection: selection
        },
        onPaginationChange: setPagination,
        onColumnFiltersChange: setFilters,
        onSortingChange: setSorts,
        onRowSelectionChange: setSelection,
        enableFilters: enabledFiltering,
        enableSorting: enabledSorting,
        enableRowSelection: row => {
            return (row.original.status === 'I' || row.original.status === 'F') && enabledSelecting
        },
        manualSorting: true,
        manualFiltering: true,
        manualPagination: true
    })

    const renderPagination = () => {
        if (isLoading || !enabledPagination) {
            return
        }
        return (
            <div className={buildClasses({ 'sticky bottom-0 left-0': stickyPagination })}>
                <TablePagination table={table} totalItems={meta?.total || 0} itemsPerPage={meta?.perPage || 0} />
            </div>
        )
    }

    const renderTable = () => {
        return (
            <Table
                isRoundedRow={dashboard}
                hasHeader={hasHeader}
                instance={table}
                onClickRow={onClickRow}
                loading={isLoading}
                maxHeight={height}
                minHeight={minHeight}
                striped
                sticky={sticky}
                selectOnClickRow={selectOnClickRow}
                customEmpty={customEmpty}
                hasFiltersRow={hasFiltersRow}
            />
        )
    }

    const isNotEmpty = !!orders.length

    const renderActions = () => {
        return (
            <>
                {(!hideActions || showCreateAction || showShowAllAction) && (
                    <CardActions border={false}>
                        {!hideActions && enabledSelecting && (
                            <Row justify='between' className='items-center'>
                                <Column>
                                    <CheckInfo>{t('frontend.dashboard.demand.table.actions.checkbox_info')}</CheckInfo>
                                </Column>
                            </Row>
                        )}

                        {(showCreateAction || showShowAllAction) && (
                            <Row justify='start'>
                                {showCreateAction ? (
                                    <Column>
                                        <Button as={Link} href='/app/demand/create'>
                                            {t('frontend.dashboard.demand.table.actions.create')}
                                        </Button>
                                    </Column>
                                ) : (
                                    <Column />
                                )}
                                {showShowAllAction && (
                                    <Column className='pl-0'>
                                        <Button
                                            as={Link}
                                            href='/app/demand'
                                            color='secondary'
                                            variant='outlined'
                                            className='h-[41px] !l-0'
                                        >
                                            {t('frontend.dashboard.demand.table.actions.show_all')}
                                        </Button>
                                    </Column>
                                )}
                            </Row>
                        )}
                    </CardActions>
                )}
                {allowActionToast && (
                    <DemandTableActionToast
                        selectedItems={selection}
                        orders={orders || []}
                        setSelection={setSelection}
                    />
                )}
            </>
        )
    }

    if (renderOnlyTable) {
        return (
            <>
                {renderTable()}
                {renderPagination()}
            </>
        )
    }

    if (typeof children === 'function') {
        return <>{children(renderTable, renderPagination, renderActions)}</>
    }

    return (
        <Card shadow rounded={rounded}>
            <CardText removePaddingX removePaddingY>
                {renderTable()}
                {renderPagination()}
            </CardText>
            {isNotEmpty && renderActions()}
        </Card>
    )
}

export default forwardRef(DemandTable)
