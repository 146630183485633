import { UsePriceOfferDetailProps } from './usePriceOffer.types'
import { getPriceOfferStepData } from '../../../services/api/price-offer/PriceOffer.service'
import { useCurrentUser } from '../../../auth/auth'
import { useQuery } from '@tanstack/react-query'
import useAxios from '../../useAxios'

const PRICE_OFFER_KEY = 'priceOffer'

const usePriceOfferGet = ({
    id,
    enabled,
    onError,
    onSuccess,
    onSettled,
    isForceUpdateToStepOne = false,
    isPriceOfferDetail = false
}: UsePriceOfferDetailProps) => {
    const { isReady, isLogged } = useCurrentUser()

    const axiosInstance = useAxios()
    return useQuery(
        [PRICE_OFFER_KEY, id, isForceUpdateToStepOne, isPriceOfferDetail],
        async ({ signal }) => {
            return getPriceOfferStepData(axiosInstance, id, { signal }, isForceUpdateToStepOne, isPriceOfferDetail)
        },
        {
            enabled: isReady && isLogged && enabled,
            onError,
            onSuccess,
            onSettled,
            cacheTime: 0
        }
    )
}

export default usePriceOfferGet
