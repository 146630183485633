import { buildClassesWithDefault } from '../../../utils/StyleHelper'
import React, { HTMLAttributes, PropsWithChildren } from 'react'

export type CardTitleProps = HTMLAttributes<HTMLDivElement> & {
    disabled?: boolean
    border?: boolean
}

const CardTitle = ({
    children,
    border = false,
    className,
    disabled = false,
    ...props
}: PropsWithChildren<CardTitleProps>) => {
    return (
        <div
            {...props}
            className={buildClassesWithDefault(
                {
                    'card-title': true,
                    'card-title-border': border,
                    disabled
                },
                className
            )}
        >
            {children}
            {disabled && <div className='disabled-overlay' />}
        </div>
    )
}

CardTitle.displayName = 'CardTitle'

export default CardTitle
