import { Filter } from '../../../../utils/ApiHelper'
import { ShortDeal } from '../../../../services/api/deal/DealService.types'
import { ShortOrder } from '../../../../services/api/order/OrderService.types'
import { TableFilterProps } from '../../../base/table/filter/TableFilter'
import React from 'react'
import SelectField from '../../../base/select-field/SelectField'
import useProductList from '../../../../hooks/api/product/useProductList'

export type DemandTableProductFilterProps = TableFilterProps<ShortOrder | ShortDeal> & {
    useProductNameForFilter?: boolean
}

const DemandOrderTableProductFilter = ({ column, useProductNameForFilter, table }: DemandTableProductFilterProps) => {
    const filter = column.getFilterValue()
    const filterValue = filter as Filter<'string', true> | undefined
    const { data = [], isLoading } = useProductList()

    return (
        <SelectField
            isMulti
            isClearable
            srOnlyLabel
            isSearchable
            disablePortal
            size='sm'
            options={data}
            isLoading={isLoading}
            label={column.columnDef.id}
            getOptionValue={option => {
                if (useProductNameForFilter) {
                    return option.label
                }
                return option.key
            }}
            getOptionLabel={option => {
                return option.label
            }}
            value={
                filterValue?.value?.map(item => {
                    return data?.find(option => {
                        if (useProductNameForFilter) {
                            return option.label === item
                        }
                        return option.key === item
                    })
                }) || []
            }
            onChange={option => {
                table.setPageIndex(0)
                if (!option.length) {
                    column.setFilterValue(null)
                    return
                }

                const value = option.map(({ key, label }) => {
                    if (useProductNameForFilter) {
                        return label
                    }
                    return key
                })

                column.setFilterValue({
                    type: 'string',
                    value: value
                })
            }}
        />
    )
}

export default DemandOrderTableProductFilter
