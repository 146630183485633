import { Column, Row } from '@tanstack/react-table'
import { ColumnHelper } from '@tanstack/table-core'
import { RowData } from '@tanstack/table-core/src/types'
import { buildClassesWithDefault } from '../../../utils/StyleHelper'
import React from 'react'
import TableCheckbox from './TableCheckbox'

export const buildSelectColumn = <TData extends RowData>(
    columnHelper: ColumnHelper<TData>,
    options: {
        id?: string
        selectAllLabel?: string
        selectThisLabel?: string
        cellClassName?:
            | string
            | ((options: {
                  column: Column<TData>
                  isStriped: boolean
                  isHeader: boolean
                  row?: Row<TData>
                  isGroup: boolean
                  isPlaceholder: boolean
              }) => string)
        sticky?: boolean
        disabled?: (row?: Row<TData>) => boolean
    } = {}
) => {
    const {
        id = 'select',
        selectAllLabel = 'Select all',
        selectThisLabel = 'Select this',
        cellClassName,
        sticky,
        disabled
    } = options

    return columnHelper.display({
        id,
        size: 42,
        header: ({ table }) => {
            return (
                <TableCheckbox
                    label={selectAllLabel}
                    checked={table.getIsAllRowsSelected()}
                    indeterminate={table.getIsSomeRowsSelected()}
                    onChange={table.getToggleAllRowsSelectedHandler()}
                    onClick={event => {
                        return event.stopPropagation()
                    }}
                />
            )
        },
        cell: ({ row }) => {
            return (
                <TableCheckbox
                    label={selectThisLabel}
                    checked={row.getIsSelected()}
                    indeterminate={row.getIsSomeSelected()}
                    onChange={row.getToggleSelectedHandler()}
                    onClick={event => {
                        return event.stopPropagation()
                    }}
                    disabled={disabled?.(row) || false}
                />
            )
        },
        meta: {
            cellClassName: options => {
                if (typeof cellClassName === 'function') {
                    return buildClassesWithDefault(cellClassName(options), 'relative', {
                        '[&>*]:!hidden': disabled?.(options.row) || false
                    })
                }
                return buildClassesWithDefault(cellClassName, 'relative', {
                    '[&>*]:!hidden': disabled?.(options.row) || false
                })
            },
            sticky
        }
    })
}
