import { DocumentDuplicateIcon, PencilIcon, PlusIcon } from '@heroicons/react/outline'
import { RowSelectionState } from '@tanstack/react-table'
import { ShortOrder } from '../../../../services/api/order/OrderService.types'
import { Transition } from '@headlessui/react'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import ActionPanel from '../../../base/action-panel/ActionPanel'
import Button from '../../../base/button/Button'

import { useDemandTableContext } from './DemandTable.context'
import React, { Dispatch, SetStateAction, useMemo } from 'react'
import Tooltip from '../../../base/tooltip/Tooltip'
import useOrderCopy from '../../../../hooks/api/order/useOrderCopy'
import usePortal from 'react-useportal'
import usePriceOfferCreate from '../../../../hooks/api/price-offer/usePriceOfferCreate'

export type DemandTableActionToastProps = {
    selectedItems: RowSelectionState
    orders: ShortOrder[]
    setSelection: Dispatch<SetStateAction<RowSelectionState>>
}

const DemandTableActionToast = ({ selectedItems, orders, setSelection }: DemandTableActionToastProps) => {
    const router = useRouter()
    const { t } = useTranslation('common')
    const { Portal } = usePortal()
    const { mutate: createPriceOffer, isLoading: isLoadingCreatePriceOffer } = usePriceOfferCreate()
    const { mutate: copyOrder, isLoading: isCopyingOrder } = useOrderCopy()
    const { setOfferPriceSelector } = useDemandTableContext()

    const selectedItemsCount = useMemo<number>(() => {
        return Object.entries(selectedItems).length
    }, [selectedItems])

    const show = useMemo<boolean>(() => {
        return selectedItemsCount > 0
    }, [selectedItemsCount])

    const getSelectedOrders = (onlyID: boolean = true) => {
        return Object.entries(selectedItems)
            .filter(([, value]) => {
                return value
            })
            .map(([key]) => {
                return onlyID ? orders[key].id : orders[key]
            })
    }

    const onClickCreatePriceOffer = () => {
        const selectedOrders = getSelectedOrders()
        createPriceOffer(
            {
                orders: selectedOrders
            },
            {
                onSuccess: data => {
                    return router.push(`/app/price-offers/create/${data.id}`)
                }
            }
        )
    }

    const onClickAddToExistsPriceOffer = () => {
        const selectedOrders = getSelectedOrders()
        setOfferPriceSelector({ show: true, selectedItems: selectedOrders })
    }

    const onClickCopyOrders = () => {
        const selectedOrderIds = getSelectedOrders()
        copyOrder(
            { orderIds: selectedOrderIds },
            {
                onSuccess: () => {
                    setSelection({})
                }
            }
        )
    }

    const demandPriceOffer: string[] = []

    const isDisabled = useMemo(() => {
        const selectedOrders: ShortOrder[] = getSelectedOrders(false)
        selectedOrders.forEach(order => {
            if (order.priceOffers.length) {
                demandPriceOffer.push(order.number)
            }
        })
        return !!demandPriceOffer.length
    }, [getSelectedOrders, demandPriceOffer])

    return (
        <Portal>
            <Transition.Root show={show}>
                <Transition.Child
                    as={ActionPanel}
                    className='fixed bottom-4 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full max-w-xl z-20 text-center'
                    enter='transition ease-in-out duration-500 transform'
                    enterFrom='!translate-y-28'
                    enterTo='translate-y-0'
                    leave='transition ease-in-out duration-300 transform'
                    leaveTo='!translate-y-28'
                    title={t('frontend.demand.create.step_2.create_items_table_section.table.actions.floating_action', {
                        count: selectedItemsCount
                    })}
                    actions={
                        <>
                            <Tooltip
                                disabled={!isDisabled}
                                contentClassName='w-64'
                                content={`
                                ${demandPriceOffer.length === 1 ? 'Poptávka ' : 'Poptávky '} 
                                ${demandPriceOffer.join(', ')} již
                                ${
                                    demandPriceOffer.length === 1
                                        ? 'byla přidána do cenové nabídky'
                                        : 'byly přidány do cenových nabídek'
                                }.
                                `}
                            >
                                <Button
                                    size='sm'
                                    leftIcon={<PlusIcon />}
                                    onClick={onClickCreatePriceOffer}
                                    loading={isLoadingCreatePriceOffer}
                                    disabled={Object.keys(selectedItems).length === 0 || isDisabled}
                                >
                                    {t('frontend.dashboard.demand.table.actions.create_price_offer')}
                                </Button>
                            </Tooltip>

                            <Tooltip
                                disabled={!isDisabled}
                                contentClassName='w-64'
                                content={`
                                ${demandPriceOffer.length === 1 ? 'Poptávka ' : 'Poptávky '} 
                                ${demandPriceOffer.join(', ')} již
                                ${
                                    demandPriceOffer.length === 1
                                        ? 'byla přidána do cenové nabídky'
                                        : 'byly přidány do cenových nabídek'
                                }.
                                `}
                            >
                                <Button
                                    size='sm'
                                    leftIcon={<PencilIcon />}
                                    disabled={isDisabled}
                                    onClick={onClickAddToExistsPriceOffer}
                                >
                                    {t('frontend.dashboard.demand.table.actions.add_to_exists_price_offer')}
                                </Button>
                            </Tooltip>
                            <Button
                                size='sm'
                                leftIcon={<DocumentDuplicateIcon />}
                                loading={isCopyingOrder}
                                onClick={onClickCopyOrders}
                                disabled={Object.keys(selectedItems).length === 0}
                            >
                                {t('frontend.dashboard.demand.table.actions.copy')}
                            </Button>
                        </>
                    }
                />
            </Transition.Root>
        </Portal>
    )
}

export default DemandTableActionToast
