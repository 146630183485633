import React from 'react'
import useProductList from '../../../../hooks/api/product/useProductList'

export type DemandTableProductNameCellProps = {
    value: string
}

const DemandOrderTableProductNameCell = ({ value }: DemandTableProductNameCellProps) => {
    const { data: products } = useProductList()
    const product = products?.find(item => {
        return item.key === value
    })

    return <span>{product ? product.label : value}</span>
}

export default DemandOrderTableProductNameCell
