import { UseOrderPageProps } from './useOrder.types'
import { getOrderPage } from '../../../services/api/order/OrderService'
import { makePaginationApiQueryBuilder } from '../../../utils/ApiHelper'
import { useCurrentUser } from '../../../auth/auth'
import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import useAxios from '../../useAxios'

const FILTER_MAPPING: Record<string, string> = {
    ts: 'created'
}

const SORT_MAPPING: Record<string, string> = {
    price: 'totalPrice'
}

const useOrderPage = ({
    filters = [],
    sorts = [],
    pagination = { pageIndex: 0, pageSize: 15 },
    onUpdateQueryParams
}: UseOrderPageProps) => {
    const { isReady, isLogged } = useCurrentUser()
    const axiosInstance = useAxios()

    const apiParams = useMemo<string>(() => {
        return makePaginationApiQueryBuilder({
            filters,
            filterMapping: FILTER_MAPPING,
            sorts,
            sortMapping: SORT_MAPPING,
            pagination
        }).get()
    }, [filters, pagination, sorts])

    return useQuery(
        ['orders', apiParams],
        async ({ signal }) => {
            return getOrderPage(axiosInstance, apiParams, { signal })
        },
        {
            keepPreviousData: true,
            enabled: isReady && isLogged,
            onSettled: data => {
                if (data?.meta?.currentPage === pagination.pageIndex + 1 && data?.data?.length) {
                    return
                }
                onUpdateQueryParams?.({
                    filters,
                    sorts,
                    pagination: {
                        pageIndex: 0,
                        pageSize: pagination.pageSize
                    }
                })
            }
        }
    )
}

export default useOrderPage
