import { UseProductListProps } from './useProduct.types'
import { getProductList } from '../../../services/api/product/ProductService'
import { useCurrentUser } from '../../../auth/auth'
import { useQuery } from '@tanstack/react-query'
import useAxios from '../../useAxios'

const useProductList = ({ enabled, onSuccess, onError }: UseProductListProps = {}) => {
    const { isReady, isLogged } = useCurrentUser()
    const axiosInstance = useAxios()

    return useQuery(
        ['productList', isLogged],
        async ({ signal }) => {
            return await getProductList(axiosInstance, { signal })
        },
        {
            enabled: isReady && isLogged && enabled,
            onError,
            onSuccess
        }
    )
}

export default useProductList
