import { AxiosError } from 'axios'
import { PriceOfferCreateRequest, PriceOfferFullData } from '../../../services/api/price-offer/PriceOffer.types'
import { createPriceOffer } from '../../../services/api/price-offer/PriceOffer.service'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import useAxios from '../../useAxios'

const usePriceOfferCreate = () => {
    const axiosInstance = useAxios()
    const queryClient = useQueryClient()

    return useMutation<PriceOfferFullData, AxiosError, PriceOfferCreateRequest>(
        ['createPriceOffer'],
        request => {
            return createPriceOffer(axiosInstance, request)
        },
        {
            onSuccess: data => {
                queryClient.setQueryData(['priceOffer', data.id], data)
                queryClient.invalidateQueries(['priceOffers'])
            }
        }
    )
}

export default usePriceOfferCreate
