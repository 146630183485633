import { AxiosError } from 'axios'
import { Order, OrderCopyRequest } from '../../../services/api/order/OrderService.types'
import { copyOrder } from '../../../services/api/order/OrderService'
import { toast } from 'react-toastify'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'next-i18next'
import useAxios from '../../useAxios'

const useOrderCopy = () => {
    const axiosInstance = useAxios()
    const queryClient = useQueryClient()
    const { t } = useTranslation('common')

    return useMutation<Order, AxiosError, OrderCopyRequest, { previousOrders?: Order[] }>(
        ['copyOrder'],
        async (request: OrderCopyRequest) => {
            const response = await copyOrder(axiosInstance, request)
            return response
        },
        {
            onMutate: async () => {
                await queryClient.cancelQueries(['orders'])
                const previousOrders = queryClient.getQueryData<Order[]>(['orders'])
                return { previousOrders }
            },
            onSuccess: data => {
                queryClient.invalidateQueries(['orders'])
                queryClient.setQueryData<Order[]>(['orders'], old => [...(old || []), data])
                toast.success(t('frontend.dashboard.demand.table.actions.copy_of_demand_was_success'))
            }
        }
    )
}

export default useOrderCopy
