import { ThemeProps } from '../theme/ThemeProps'
import { buildClasses, buildClassesWithDefault } from '../../../utils/StyleHelper'
import { mergeRefs } from 'react-merge-refs'
import React, { ForwardedRef, InputHTMLAttributes, ReactNode, Ref, forwardRef, useEffect, useId, useRef } from 'react'
import Tooltip, { TooltipRef } from '../tooltip/Tooltip'

export type CheckboxProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'id' | 'type'> &
    ThemeProps & {
        label: ReactNode
        hideLabel?: boolean
        valid?: boolean
        indeterminate?: boolean
        message?: string
        renderMessageInTooltip?: boolean
        labelClassName?: string
        inputClassName?: string
        tooltipRef?: Ref<TooltipRef>
    }

/*
 * This component returns the event in the change event and receive the boolean in the "checked" value of the component
 * */
const Checkbox = (
    {
        label,
        hideLabel,
        valid = true,
        color = 'primary',
        indeterminate = false,
        renderMessageInTooltip,
        tooltipRef,
        message,
        inputClassName,
        disabled,
        checked,
        ...props
    }: CheckboxProps,
    ref: ForwardedRef<HTMLInputElement>
) => {
    const randomId = useId()
    const defaultRef = useRef<HTMLInputElement>()
    const resolvedRef = mergeRefs([defaultRef, ref])

    useEffect(() => {
        if (defaultRef.current) {
            defaultRef.current.indeterminate = indeterminate
        }
    }, [indeterminate])

    const controlledIsChecked = !!checked

    const renderInput = (additionalRef?: Ref<HTMLInputElement>, additionalProps: Record<string, unknown> = {}) => {
        return (
            <>
                <div {...additionalProps} ref={additionalRef}>
                    <input
                        {...props}
                        className={inputClassName}
                        disabled={disabled}
                        type='checkbox'
                        ref={resolvedRef}
                        id={randomId}
                        data-test-name={props.name}
                        data-test-element-type='checkbox'
                        data-test-element='input'
                        checked={controlledIsChecked}
                    />
                </div>
            </>
        )
    }

    return (
        <div className={buildClassesWithDefault({ invalid: !valid, [color]: true, disabled }, 'checkbox')}>
            {renderMessageInTooltip ? (
                <Tooltip
                    content={message}
                    backgroundColor={!valid ? 'danger.DEFAULT' : 'primary.DEFAULT'}
                    textColor='white'
                    ref={tooltipRef}
                    disabled={!renderMessageInTooltip || !message}
                    lazy={false}
                >
                    {renderInput}
                </Tooltip>
            ) : (
                renderInput()
            )}
            <label
                htmlFor={randomId}
                className={buildClasses({ 'sr-only': hideLabel || false })}
                {...(typeof label === 'string' ? { dangerouslySetInnerHTML: { __html: label } } : {})}
            >
                {typeof label !== 'string' ? label : null}
            </label>
            {!renderMessageInTooltip && message && <span className='checkbox-message'>{message}</span>}
        </div>
    )
}

export default forwardRef(Checkbox)
