import { buildClassesWithDefault } from '../../../utils/StyleHelper'
import React, { ForwardedRef, HTMLAttributes, ReactNode, forwardRef } from 'react'

export type ActionPanelProps = Omit<HTMLAttributes<HTMLDivElement>, 'children'> & {
    title: string
    actions?: ReactNode
}

const ActionPanel = ({ title, className, actions, ...props }: ActionPanelProps, ref: ForwardedRef<HTMLDivElement>) => {
    return (
        <div className={buildClassesWithDefault('action-panel', className)} ref={ref} {...props}>
            <div className='flex-1 w-0 p-4'>
                <div className='flex items-start'>
                    <div className='ml-3 flex-1'>
                        <p className='text-body-regular font-medium'>{title}</p>
                        <div className='mt-4 flex gap-2.5 items-center justify-end'>{actions}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default forwardRef(ActionPanel)
