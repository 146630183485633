const getDefaultSelectValue = (data: { selectId: string; value: string }[], selectId: string) => {
    return data.filter(item => {
        return item.selectId === selectId
    })?.[0]
}

type SelectName = `orders.${number}.expedition` | `orders.${number}.expedition_n` | `orders.${number}.ts_delivery`

type SetValueType = (
    formName: SelectName,
    formValue: string,
    options?: Partial<{
        shouldValidate: boolean
        shouldDirty: boolean
        shouldTouch: boolean
    }>
) => void

const setDefaultSelectValue = (name: SelectName, value: string, setValue: SetValueType) => {
    setValue(name, value, { shouldDirty: false, shouldTouch: false })
}

const getDefaultSelectsValues = (dataForSelect: { selectId: string; value: string }[]) => {
    const tsDeliveryData = getDefaultSelectValue(dataForSelect, 'ts_delivery')

    const expedition = getDefaultSelectValue(dataForSelect, 'expedition')

    const expeditionNext = getDefaultSelectValue(dataForSelect, 'expedition_n')
    return { tsDeliveryData, expedition, expeditionNext }
}

const setDefaultSelectsValues = (
    args: {
        tsDeliveryData: {
            selectId: string
            value: string
        }
        expedition: {
            selectId: string
            value: string
        }
        expeditionNext: {
            selectId: string
            value: string
        }
    },
    setValue: SetValueType,
    tsDelivery: string,
    index: number
) => {
    const { expedition, expeditionNext, tsDeliveryData } = args
    if (tsDeliveryData && !tsDelivery) {
        setDefaultSelectValue(`orders.${index}.ts_delivery`, tsDeliveryData.value, setValue)
    }
    setDefaultSelectValue(`orders.${index}.expedition`, expedition.value, setValue)
    setDefaultSelectValue(`orders.${index}.expedition_n`, expeditionNext.value, setValue)
}

export const handleDefaultSelectsValues = (
    dataForSelect: { selectId: string; value: string }[],
    setValue: SetValueType,
    tsDelivery: string,
    index: number
) => {
    setDefaultSelectsValues(getDefaultSelectsValues(dataForSelect), setValue, tsDelivery, index)
}
