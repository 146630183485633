import { buildClassesWithDefault } from '../../../utils/StyleHelper'
import React, { AnchorHTMLAttributes, ButtonHTMLAttributes, PropsWithChildren } from 'react'

type PaginationButtonSize = 'sm' | 'md'

export type PaginationButtonProps = AnchorHTMLAttributes<HTMLAnchorElement> &
    ButtonHTMLAttributes<HTMLButtonElement> & {
        link?: boolean
        active?: boolean
        roundLeft?: boolean
        roundRight?: boolean
        roundTop?: boolean
        roundBottom?: boolean
        roundFull?: boolean
        disableHoverEffect?: boolean
        size?: PaginationButtonSize
    }

const PaginationButton = ({
    children,
    link = false,
    className,
    active = false,
    roundLeft = false,
    roundRight = false,
    roundTop = false,
    roundBottom = false,
    roundFull = false,
    disableHoverEffect = false,
    size = 'md',
    ...props
}: PropsWithChildren<PaginationButtonProps>) => {
    const classes = buildClassesWithDefault(
        {
            'pagination-button': true,
            active: active,
            'rounded-l-md': roundLeft || roundFull,
            'rounded-r-md': roundRight || roundFull,
            'rounded-t-md': roundTop || roundFull,
            'rounded-b-md': roundBottom || roundFull,
            'no-hover-effect': disableHoverEffect,
            [size]: true
        },
        className
    )

    if (link) {
        return (
            <a {...props} className={classes} aria-current={active ?? 'page'}>
                {children}
            </a>
        )
    }

    return (
        <button {...props} className={classes} aria-current={active ?? 'page'}>
            {children}
        </button>
    )
}

export default PaginationButton
