import { AxiosError } from 'axios'
import { Controller, UseFormReturn, useFieldArray } from 'react-hook-form'
import { formatISO, parseISO } from 'date-fns'
import { getFieldErrors } from '../../../utils/ApiHelper'
import { registerFormInputErrors } from '../../../utils/FormHelper'
import { useTranslation } from 'next-i18next'
import DatePicker from '../../base/date-picker/DatePicker'
import PlainTable from '../../base/plain-table/PlainTable'
import PlainTableBody from '../../base/plain-table/PlainTableBody'
import PlainTableBodyRow from '../../base/plain-table/PlainTableBodyRow'
import PlainTableBodyRowCell from '../../base/plain-table/PlainTableBodyRowCell'
import PlainTableHeader from '../../base/plain-table/PlainTableHeader'
import PlainTableHeaderRow from '../../base/plain-table/PlainTableHeaderRow'
import PlainTableHeaderRowCell from '../../base/plain-table/PlainTableHeaderRowCell'
import React, { useState } from 'react'
import SelectField from '../../base/select-field/SelectField'

export type OrdersFormValues = {
    orders: {
        productName: string
        number: string
        orderId: number
        expedition: string
        expedition_n: string
        ts_delivery: string
        dpd_pay: boolean
    }[]
}

type OrderDemandSendToProductionTableProps = {
    form: UseFormReturn<OrdersFormValues, any>
    error: AxiosError<unknown, any>
    data: {
        expeditions: { selectId: string; name: string; value: string; hidden: boolean }[]
    }[]
}

const OrderDemandSendToProductionTable = ({ form, error, data }: OrderDemandSendToProductionTableProps) => {
    const { t } = useTranslation('common')
    const { control } = form
    const [openDatePickerId, setOpenDatePickerId] = useState<string | undefined>(undefined)
    const { fields } = useFieldArray({
        control,
        name: 'orders',
        keyName: 'id'
    })

    const getTsDeliveryValidationProps = (index: number) => {
        if (!getFieldErrors(error, 'date')?.length) {
            return registerFormInputErrors(form, `orders.${index}.ts_delivery`)
        }

        return {
            valid: false,
            message: getFieldErrors(error, 'date')?.[index]
        }
    }

    return (
        <PlainTable disableNativeScrollbar>
            <PlainTableHeader lightColorHeader>
                <PlainTableHeaderRow isRounded>
                    <PlainTableHeaderRowCell className='!text-gray-800 !text-xs'>
                        {t('frontend.price_offers.sent_to_production_modal.header.product')}
                    </PlainTableHeaderRowCell>
                    <PlainTableHeaderRowCell className='!text-gray-800 !text-xs'>
                        {t('frontend.price_offers.sent_to_production_modal.header.demand')}
                    </PlainTableHeaderRowCell>
                    <PlainTableHeaderRowCell className='!text-gray-800 !text-xs'>
                        {t('frontend.price_offers.sent_to_production_modal.header.date_production')}
                    </PlainTableHeaderRowCell>
                    <PlainTableHeaderRowCell className='!text-gray-800 !text-xs'>
                        {t('frontend.price_offers.sent_to_production_modal.header.delivery')}
                    </PlainTableHeaderRowCell>
                </PlainTableHeaderRow>
            </PlainTableHeader>
            <PlainTableBody>
                {fields.map((demand, index) => {
                    return (
                        <PlainTableBodyRow key={demand.id}>
                            <PlainTableBodyRowCell borderBottom>{demand.productName}</PlainTableBodyRowCell>
                            <PlainTableBodyRowCell borderBottom>{demand.number}</PlainTableBodyRowCell>
                            <PlainTableBodyRowCell borderBottom>
                                <Controller
                                    control={control}
                                    name={`orders.${index}.ts_delivery`}
                                    render={({ field: { onChange, onBlur, value } }) => {
                                        return (
                                            <DatePicker
                                                popperModifiers={[
                                                    {
                                                        name: 'flip',
                                                        options: {
                                                            fallbackPlacements: ['auto']
                                                        }
                                                    }
                                                ]}
                                                inputClassName='border-secondary'
                                                selected={value ? parseISO(value) : undefined}
                                                onChange={date => {
                                                    return onChange(date ? formatISO(date) : undefined)
                                                }}
                                                onClickOutside={() => {
                                                    return setOpenDatePickerId(undefined)
                                                }}
                                                onInputClick={() => {
                                                    return setOpenDatePickerId(demand.id)
                                                }}
                                                onSelect={() => {
                                                    return setOpenDatePickerId(undefined)
                                                }}
                                                open={openDatePickerId === demand.id}
                                                autoFocus={false}
                                                onBlur={onBlur}
                                                dateFormat='dd.MM.yyyy'
                                                textFieldProps={getTsDeliveryValidationProps(index)}
                                                {...registerFormInputErrors(form, `orders.${index}.ts_delivery`)}
                                            />
                                        )
                                    }}
                                />
                            </PlainTableBodyRowCell>
                            <PlainTableBodyRowCell borderBottom>
                                <Controller
                                    control={control}
                                    name={`orders.${index}.expedition`}
                                    render={({ field: { onChange, onBlur, value } }) => {
                                        const expeditions = data?.[index]?.expeditions
                                        return (
                                            <div>
                                                <SelectField
                                                    menuPosition='absolute'
                                                    className='w-[250px]'
                                                    options={
                                                        expeditions?.filter(item => {
                                                            return !item.hidden && item.selectId === 'expedition'
                                                        }) || []
                                                    }
                                                    getOptionLabel={option => {
                                                        return option.name
                                                    }}
                                                    getOptionValue={option => {
                                                        return option.value
                                                    }}
                                                    value={expeditions?.find(item => {
                                                        return item.value === value
                                                    })}
                                                    onChange={({ value: onChangeValue }) => {
                                                        return onChange(onChangeValue)
                                                    }}
                                                    onBlur={onBlur}
                                                    {...registerFormInputErrors(form, `orders.${index}.expedition`)}
                                                />
                                            </div>
                                        )
                                    }}
                                />
                            </PlainTableBodyRowCell>
                        </PlainTableBodyRow>
                    )
                })}
            </PlainTableBody>
        </PlainTable>
    )
}

export default OrderDemandSendToProductionTable
