import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'
import { ThemeProps } from '../theme/ThemeProps'
import { Trans, useTranslation } from 'next-i18next'
import { buildClassesWithDefault } from '../../../utils/StyleHelper'
import { getPageOptions } from '../../../utils/Helper'
import PaginationButton from './PaginationButton'
import React, { HTMLAttributes, ReactNode, useMemo } from 'react'

export type PaginationProps = Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> &
    ThemeProps & {
        prevLabel?: string
        nextLabel?: string
        renderInfo?: (page: number, pageCount: number) => ReactNode
        page: number
        pageCount: number
        itemsPerPage: number
        totalItems: number
        visiblePageButtons?: number
        onChange?: (value: number) => void
        bordered?: boolean
        removePaddingX?: boolean
        removePaddingY?: boolean
    }

const Pagination = ({
    className,
    color = 'primary',
    prevLabel,
    nextLabel,
    renderInfo,
    page,
    pageCount,
    visiblePageButtons = 3,
    totalItems,
    itemsPerPage,
    onChange,
    bordered = true,
    removePaddingX = false,
    removePaddingY = false
}: PaginationProps) => {
    const { t } = useTranslation()

    const pageVisibleNumbers = useMemo<number[]>(() => {
        return getPageOptions(pageCount - 1, page - 1, visiblePageButtons)
    }, [pageCount, visiblePageButtons, page])

    const canNext = () => {
        return page < pageCount
    }
    const canPrevious = () => {
        return page > 1
    }
    const changePage = (value: number) => {
        return onChange && onChange(value)
    }
    const nextPage = () => {
        return canNext() && changePage(page + 1)
    }
    const previousPage = () => {
        return canPrevious() && changePage(page - 1)
    }

    const renderPaginationInfo = () => {
        if (pageCount <= 0) {
            return <div />
        }

        const itemsFrom = (page - 1) * itemsPerPage + 1
        const itemsTo = Math.min(page * itemsPerPage, totalItems)

        return (
            <div>
                <p className='pagination-info'>
                    {renderInfo ? (
                        renderInfo(page, pageCount)
                    ) : (
                        <Trans
                            i18nKey='frontend.shared.pagination.showing'
                            values={{
                                itemsFrom: itemsTo === 0 ? 0 : itemsFrom,
                                itemsTo,
                                totalItems
                            }}
                            components={{ span: <span /> }}
                        />
                    )}
                </p>
            </div>
        )
    }

    const renderThreeDotsItem = () => {
        return (
            <PaginationButton disabled disableHoverEffect size='sm'>
                ...
            </PaginationButton>
        )
    }

    const renderFirstPageItem = () => {
        if (page !== 1 && !pageVisibleNumbers.includes(1)) {
            return (
                <>
                    <PaginationButton
                        onClick={() => {
                            return changePage(1)
                        }}
                    >
                        1
                    </PaginationButton>
                    {renderThreeDotsItem()}
                </>
            )
        }
    }

    const renderLastPageItem = () => {
        if (page !== pageCount && !pageVisibleNumbers.includes(pageCount)) {
            return (
                <>
                    {renderThreeDotsItem()}
                    <PaginationButton
                        onClick={() => {
                            return changePage(pageCount)
                        }}
                    >
                        {pageCount}
                    </PaginationButton>
                </>
            )
        }
    }

    const renderItems = () => {
        if (pageCount <= 0) {
            return
        }
        return (
            <>
                {renderFirstPageItem()}
                {pageVisibleNumbers.map(item => {
                    return (
                        <PaginationButton
                            key={item}
                            active={item === page}
                            onClick={() => {
                                return changePage(item)
                            }}
                        >
                            {item}
                        </PaginationButton>
                    )
                })}
                {renderLastPageItem()}
            </>
        )
    }

    const renderMobilePagination = () => {
        return (
            <div className='pagination-mobile'>
                <PaginationButton className='relative' roundFull disabled={!canPrevious()} onClick={previousPage}>
                    {prevLabel || t('frontend.shared.pagination.previous.label')}
                </PaginationButton>
                <PaginationButton className='relative' roundFull disabled={!canNext()} onClick={nextPage}>
                    {nextLabel || t('frontend.shared.pagination.next.label')}
                </PaginationButton>
            </div>
        )
    }

    const renderComputerPagination = () => {
        return (
            <div>
                <nav aria-label='Pagination'>
                    <PaginationButton
                        className='relative'
                        roundLeft
                        size='sm'
                        aria-hidden='true'
                        disabled={!canPrevious()}
                        onClick={previousPage}
                    >
                        <span className='sr-only'>{prevLabel || t('frontend.shared.pagination.previous.label')}</span>
                        <ChevronLeftIcon className='h-5 w-5' aria-hidden='true' />
                    </PaginationButton>
                    {/* Render number buttons */}
                    {renderItems()}
                    <PaginationButton
                        className='relative'
                        roundRight
                        size='sm'
                        aria-hidden='true'
                        disabled={!canNext()}
                        onClick={nextPage}
                    >
                        <span className='sr-only'>{nextLabel || t('frontend.shared.pagination.next.label')}</span>
                        <ChevronRightIcon className='h-5 w-5' aria-hidden='true' />
                    </PaginationButton>
                </nav>
            </div>
        )
    }

    return (
        <div
            className={buildClassesWithDefault(
                {
                    pagination: true,
                    [color]: true,
                    bordered,
                    paddingY: !removePaddingY,
                    paddingX: !removePaddingX
                },
                className
            )}
        >
            {renderMobilePagination()}
            <div className='pagination-computer'>
                {renderPaginationInfo()}
                {renderComputerPagination()}
            </div>
        </div>
    )
}

export default Pagination
