import { DocumentDuplicateIcon, PencilIcon, TrashIcon } from '@heroicons/react/outline'
import { Row } from '@tanstack/react-table'
import { ShortOrder } from '../../../../services/api/order/OrderService.types'
import { buildClasses } from '../../../../utils/StyleHelper'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import Button from '../../../base/button/Button'
import ConfirmDialog from '../../../base/confirm-dialog/ConfirmDialog'
import InvoiceIcon from '../../../base/icon/InvoiceIcon'
import Link from 'next/link'
import React, { useMemo } from 'react'
import Tooltip from '../../../base/tooltip/Tooltip'
import useOrderCopy from '../../../../hooks/api/order/useOrderCopy'
import useOrderDelete from '../../../../hooks/api/order/useOrderDelete'
import useTheme from '../../../../hooks/useTheme'

export type DemandTableActionCellProps = {
    row: Row<ShortOrder>
    canDelete?: boolean
}

const DemandTableActionCell = ({ row, canDelete }: DemandTableActionCellProps) => {
    const { t } = useTranslation('common')
    const { mutate: deleteOrder, isLoading: isDeletingOrder } = useOrderDelete()
    const { mutate: copyOrder, isLoading: isCopyingOrder } = useOrderCopy()
    const { secondaryColor } = useTheme()

    const isDemandWriteable = row.original.status === 'I'
    const router = useRouter()

    const buttonClasses = buildClasses({
        'w-16': true
    })

    const demandEditLink = useMemo<string>(() => {
        return `/app/demand/create/${row.original.id}?edit=true&step=${row.original?.expedition?.length > 0 ? 3 : 2}`
    }, [row.original?.expedition?.length, row.original.id])

    const isDemandInPriceOffer = row.original.priceOffers.length > 0
    const redirectToPriceOfferDetail = (id: number) => {
        return router.push(`/app/price-offers/create/${id}`)
    }

    return (
        <div className='flex items-center gap-2.5'>
            {isDemandWriteable ? (
                <Button as={Link} href={demandEditLink} size='sm' className={buttonClasses}>
                    {t('frontend.dashboard.demand.table.action.edit.force')}
                </Button>
            ) : (
                <Button as={Link} href={`/app/demand/${row.original.id}`} size='sm' className={buttonClasses}>
                    {t('frontend.dashboard.demand.table.action.detail')}
                </Button>
            )}

            {!isDemandWriteable && (
                <Tooltip content={t('frontend.dashboard.demand.table.action.edit')}>
                    <Button icon variant='text' color='secondary' as={Link} href={demandEditLink}>
                        <PencilIcon />
                    </Button>
                </Tooltip>
            )}
            {isDemandInPriceOffer && !isDemandWriteable && (
                <Tooltip content={t('frontend.dashboard.demand.table.action.detail_price_offer')}>
                    <Button
                        icon
                        variant='text'
                        color='secondary'
                        onClick={() => {
                            const priceOfferId = row.original.priceOffers[0].id

                            return redirectToPriceOfferDetail(priceOfferId)
                        }}
                    >
                        <InvoiceIcon fill={secondaryColor} />
                    </Button>
                </Tooltip>
            )}
            <Tooltip content={t('Zkopírovat poptávku')}>
                <Button
                    icon
                    color='secondary'
                    variant='text'
                    loading={isCopyingOrder}
                    onClick={() => copyOrder({ orderIds: [row.original.id] })}
                >
                    <DocumentDuplicateIcon />
                </Button>
            </Tooltip>
            {canDelete && (
                <ConfirmDialog
                    text={t('frontend.demand.delete.confirm_dialog')}
                    okLabel={t('frontend.demand.delete.confirm_dialog.confirm_button')}
                    onConfirm={() => {
                        return deleteOrder(row.original.id)
                    }}
                >
                    {open => {
                        return (
                            <Tooltip content={t('frontend.dashboard.demand.table.action.delete')}>
                                <Button icon variant='text' loading={isDeletingOrder} onClick={open}>
                                    <TrashIcon />
                                </Button>
                            </Tooltip>
                        )
                    }}
                </ConfirmDialog>
            )}
        </div>
    )
}

export default DemandTableActionCell
